import { combineReducers } from "redux";
// eslint-disable-next-line no-unused-vars
import moment from "moment";

//reducer
import loginReducer from "./loginReducer/loginReducer";
import popupRootReducer from "./popupReducer/index";
import routerReducer from "./routerReducer/routerReducer";
import deviceReducer from "./deviceReducer/deviceReducer";
import userReducer from "./userReducer/userReducer";
import locationReducer from './locationReducer/locationReducer';
import socketReducer from "./socketReducer/socketReducer";
import qrReducer from './qrReducer/qrReducer';
import visitorReducer from './visitorReducer/visitorReducer';
import employeeReducer from './employeeReducer/employeeReducer';
import activityReducer from './activityReducer/activityReducer';
import companyReducer from './companyReducer/companyReducer';
import filterReducer from './filterReducer/filterReducer';
import systemReducer from "./systemReducer/systemReducer";
import attendanceReducer from './attendanceReducer/attendanceReducer';
import LogUserReducer from './LogUserReducer/LogUserReducer';
// eslint-disable-next-line no-unused-vars
import { LOGOUT_SUCCESS, LOGIN_SUCCESS } from "../actions/types";

const appReducer = combineReducers({
  ...popupRootReducer,
  routerReducer,
  loginReducer,
  deviceReducer,
  userReducer,
  locationReducer,
  socketReducer,
  qrReducer,
  visitorReducer,
  employeeReducer,
  activityReducer,
  companyReducer,
  filterReducer,
  systemReducer,
  attendanceReducer,
  LogUserReducer
});

const mainReducer = (state, action) => {
  if (action.type === LOGOUT_SUCCESS) {
    state = undefined;
  }
  return appReducer(state, action);
};


export default mainReducer;
