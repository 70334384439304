//host product
// export const host = "https://io.wee.vn:8000/";
// export const hostAttendance = "https://io.wee.vn:8001/";
// export const hostDevice="http://18.141.115.122:9000/"
//host QC
// export const host = "https://weeio.weedigital.vn/v1/";
// export const hostAttendance = "https://weeio.weedigital.vn:8101/";
// export const hostDevice="https://weeio.weedigital.vn:9000/"
// export const hostSocket="wss://weeio.weedigital.vn/ws/"
// export const hostSocket="wss://io.wee.vn/ws/"
export const hostSocket = "wss://weeio.vn/ws/"

// auto CI/CD
// export const host = "https://io.wee.vn/v1/"
export const host = "https://weeio.vn/v1/"
