import * as types from "../../actions/types";

export const initialState = {
    lstActivity: [],
    activityDetail: {},
    total: 0,
    pageSize: 0,
    pageNum: 0,
    data: {},
    isFilter: false
};

export default function activityReducer(state = initialState, action) {
    switch (action.type) {
        case types.GET_FILTER_ACTIVITY: {
            return {
                ...state,
                lstActivity: action.pageNumber === 1
                    ? (action.data.Data || [])
                    : action.data.Data
                        ? [...state.lstActivity, ...action.data.Data] : [],
                total: action.data.Total || 0,
                err: '',
                type: action.type,
                isFilter: action.isFilter
            }
        }
        case types.GET_FILTER_ACTIVITY_ERROR: {
            return {
                ...state,
                // lstActivity:[],
                // total: 0,
                err: action.error,
                type: action.type,
            }
        }

        case types.GET_ACTIVITY:
        case types.GET_ACTIVITY_ERROR:
            return {
                ...state,
                activityDetail: {
                    type: action.type,
                    data: action.data
                }
            }
        case types.CLEAR_ACTIVITY:
            return {
                ...state,
                activityDetail: {}
            }

        default:
            return state;
    }
}
